<template>
    <side-panel
        id="main-panel"
        :class="{
            collapsed: mainCollapsed,
            'table-variant': config?.vehicleListModeView == 'table' && !mainCollapsed,
            'table-fluid': config?.vehicleListFluidTable
        }"
        :data-table-fields-count="
            config?.vehicleListModeView == 'table'
                ? config?.vehicleListTableFields?.fields.length
                : null
        "
        :scrollable-body="config?.vehicleListModeView != 'table'"
    >
        <template #head>
            <golia-button
                @click="scrollTopVehicles"
                class="btn-scroll-vehicles"
                :class="pinEvent ? 'animated' : ''"
                :label="__('geofleet.mainpanel.pinned_vehicles')"
                variant="btn-fill success"
                icon="fa-circle-chevron-up"
                iconPosition="right"
            />

            <transition name="collapse-fade" mode="in-out">
                <div v-show="!mainCollapsed" :class="{ 'form-section': true }">
                    <ul class="icons-list list-inline text-center">
                        <!-- Search vehicles -->
                        <li
                            class="list-inline-item position-relative"
                            v-b-tooltip.hover
                            :title="__('search_vehicle')"
                        >
                            <div class="icon-indicator" v-show="q">
                                <i class="fa-solid fa-check"></i>
                            </div>
                            <button v-b-toggle.collapse-search class="btn btn-transparent rounded">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </button>
                        </li>
                        <!-- Sort vehicles -->
                        <li
                            class="list-inline-item position-relative"
                            v-b-tooltip.hover
                            :title="__('sort_vehicles')"
                            v-if="config?.vehicleListModeView != 'table'"
                        >
                            <div
                                class="icon-indicator"
                                v-if="countEntries(sort, 'active', true) > 0"
                            >
                                {{ countEntries(sort, 'active', true) }}
                            </div>
                            <button v-b-toggle.collapse-sort class="btn btn-transparent rounded">
                                <i class="fa-solid fa-arrow-down-a-z"></i>
                            </button>
                        </li>
                        <!-- Multicompany -->
                        <li
                            class="list-inline-item position-relative"
                            v-if="Object.keys(localCompanies).length > 1"
                            v-b-tooltip.hover
                            :title="__('multicompany')"
                        >
                            <div class="icon-indicator">
                                {{ Object.keys(localCompanies).length }}
                            </div>
                            <button
                                v-b-toggle.collapse-multiazienda
                                class="btn btn-transparent rounded"
                            >
                                <i class="fa-solid fa-buildings"></i>
                            </button>
                        </li>
                    </ul>

                    <!-- Search vehicles collapse -->
                    <b-collapse id="collapse-search" accordion="icons-header" visible>
                        <div class="d-flex align-items-center" style="gap: 10px">
                            <div class="flex-grow-1">
                                <input-search
                                    v-model="q"
                                    @resetSearch="resetSearch"
                                    ref="search_input"
                                />
                            </div>
                            <div class="flex-grow-0">
                                <!-- FILTERS BUTTON -->
                                <golia-button
                                    variant="btn-circle btn-fill dark-blue"
                                    icon="fa-fw fa-filter"
                                    :indicator="activeSearches"
                                    style="margin-bottom: 10px; padding: 5px 7px"
                                    v-b-modal.modal-search-trucks
                                />

                                <!-- QUICK ALERTS FILTER -->
                                <golia-button
                                    variant="btn-circle btn-fill"
                                    :class="{
                                        'dark-blue': !activeFilters.any_problem,
                                        success: activeFilters.any_problem
                                    }"
                                    icon="fa-fw fa-triangle-exclamation"
                                    :indicator="getTrucksWithAlerts.length"
                                    style="margin-bottom: 10px; margin-left: 10px; padding: 5px 7px"
                                    @click="triggerFilter('any_problem')"
                                />

                                <template v-if="config?.vehicleListModeView == 'table'">
                                    <!-- Quick Filters -->
                                    <golia-button
                                        variant="btn-circle btn-fill dark-blue ms-2"
                                        icon-weight="fak"
                                        icon="fa-fw fa-user-nocard"
                                        :indicator="activeFilters.no_card ? 1 : 0"
                                        style="margin-bottom: 10px; padding: 5px 7px"
                                        @click="triggerFilter('no_card')"
                                        v-b-tooltip.left.hover
                                        :title="__('no_card')"
                                    />
                                    <golia-button
                                        variant="btn-circle btn-fill dark-blue ms-2"
                                        icon="fa-fw fa-user-group"
                                        :indicator="activeFilters.codriving ? 1 : 0"
                                        style="margin-bottom: 10px; padding: 5px 7px"
                                        @click="triggerFilter('codriving')"
                                        v-b-tooltip.left.hover
                                        :title="__('codriving')"
                                    />
                                    <golia-button
                                        variant="btn-circle btn-fill dark-blue ms-2"
                                        icon="fa-fw fa-lightbulb"
                                        :indicator="activeFilters.ignition ? 1 : 0"
                                        style="margin-bottom: 10px; padding: 5px 7px"
                                        @click="triggerFilter('ignition')"
                                        v-b-tooltip.left.hover
                                        :title="__('ignition')"
                                    />
                                </template>
                            </div>
                        </div>
                    </b-collapse>

                    <!-- Filter modal -->
                    <b-modal
                        id="modal-search-trucks"
                        :title="__('geofleet.mainpanel.search_trucks_title')"
                        size="lg"
                    >
                        <filter-trucks :nearVehicles="nearVehicles" v-model="activeFilters" />

                        <template #modal-footer="{}">
                            <!-- Chiude modale / Torna indietro -->
                            <golia-button
                                :label="__('cerca')"
                                :loading="
                                    nearVehicles.active && nearVehicles.searchedVehicles.length == 0
                                "
                                @click="
                                    filterTrucks(true),
                                        truck_filtered_out.length > 0
                                            ? $snotify.success(__('filter_applied'), {
                                                  position: 'centerTop'
                                              })
                                            : ''
                                "
                                icon="fa-magnifying-glass"
                                variant="btn-success"
                            />
                            <golia-button
                                :label="__('erase')"
                                @click="resetFilerFields()"
                                icon="fa-eraser"
                                variant="btn-fill gray"
                                class="ms-2"
                            />
                            <golia-button
                                :label="__('cancel_search')"
                                @click="
                                    resetFilerFields() && $bvModal.hide('modal-search-trucks'),
                                        $snotify.info(__('filter_canceled'), {
                                            position: 'centerTop'
                                        })
                                "
                                icon="fa-filter-circle-xmark"
                                variant="btn-fill dark-blue"
                                class="ms-auto"
                            />
                        </template>
                    </b-modal>

                    <!-- Sort vehicles collapse -->
                    <b-collapse id="collapse-sort" accordion="icons-header">
                        <div class="container-fluid modified-gutters">
                            <sort-trucks
                                :sort="sort"
                                :sortAction="sortAction"
                                @sortToggle="toggleSorter"
                                ref="sort_input"
                            />
                        </div>
                    </b-collapse>

                    <!-- Multicompany collapse -->
                    <b-collapse
                        id="collapse-multiazienda"
                        accordion="icons-header"
                        v-if="Object.keys(localCompanies).length > 1"
                    >
                        <div class="container-fluid modified-gutters">
                            <div
                                class="single-toggle d-flex justify-content-between align-items-center"
                                v-for="(company, index) in filterCompanies"
                                :key="index"
                                :class="company.active === true ? 'active' : ''"
                                @click="company.active = !company.active"
                            >
                                {{ company.name }} ({{ companyCounts[index] }})
                                <i
                                    class="fa-solid"
                                    :class="company.active ? 'fa-check' : 'fa-xmark'"
                                ></i>
                            </div>
                        </div>
                    </b-collapse>

                    <p class="text-center mb-0" v-if="!mainCollapsed">
                        <small
                            ><b>{{ __('vehicles') }}: </b>
                            <span
                                :class="truck_filtered_out.length > 0 ? 'text-success' : ''"
                                v-if="truck_filtered_out.length"
                            >
                                {{ storeTrucks.length - truck_filtered_out.length }} /
                            </span>
                        </small>
                        <small>{{ storeTrucks.length }}</small>
                    </p>
                </div>
            </transition>

            <div
                @click="
                    toggleCollapsePanel({
                        panel: 'main',
                        with_loading: config?.vehicleListModeView == 'table'
                    })
                "
                class="collapse-button"
                v-if="$mq != 'xs' && $mq != 'sm'"
            >
                <i v-if="mainCollapsed" class="fa-solid fa-arrow-left-from-line"></i>
                <i v-else class="fa-solid fa-arrow-right-from-line"></i>
            </div>

            <!-- Mobile close button (return to home) -->
            <div
                @click="$router.push({ name: 'geofleet.index' })"
                class="close-btn"
                v-if="($mq == 'xs' || $mq == 'sm') && direction != 'horizontal'"
            >
                <i class="fa-solid fa-xmark"></i>
            </div>
        </template>

        <template #body>
            <template v-if="config?.vehicleListModeView == 'table' && !mainCollapsed">
                <table-view-truck-list
                    :trucks="listActiveTrucks"
                    :drivers="drivers"
                    :collapsed="mainCollapsed"
                    @focus="
                        (truck) =>
                            emitFocusEvent(truck, [
                                {
                                    type: 'mutation',
                                    name: 'collapsePanel',
                                    payload: { panel: 'main', with_loading: true }
                                }
                            ])
                    "
                    @plan="emitPlanEvent"
                    @activity="emitActivityEvent"
                />
            </template>
            <template v-else>
                <recycle-scroller
                    :items="listActiveTrucks"
                    :item-size="placeholderItemHeight.current"
                    :buffer="500"
                    :direction="direction"
                    class="truck-section custom-scroller scroller"
                    ref="dynamic-truck-scroller"
                >
                    <template #before>
                        <golia-button
                            class="btn-close-scroll-vehicles"
                            :label="__('geofleet.mainpanel.pinned_vehicles')"
                            variant="btn-fill gray fenced"
                            size="btn-sm"
                            :icon="!pinCollapse ? 'fa-chevron-up' : 'fa-chevron-down'"
                            iconPosition="right"
                            v-if="computedPinnedTrucks.length > 0"
                            v-show="!mainCollapsed"
                            v-b-toggle.pinned-vehicles
                            @click="pinCollapse = !pinCollapse"
                        />
                        <div v-if="computedPinnedTrucks.length > 0" class="pinned-vehicles">
                            <b-collapse id="pinned-vehicles" visible>
                                <truck-list-item
                                    class="truck-list-item"
                                    v-for="truck of computedPinnedTrucks"
                                    :key="truck.id"
                                    :truck="truck"
                                    :driver="drivers[truck.lastpoint.driver]"
                                    :collapsed="mainCollapsed"
                                    @click="emitFocusEvent(truck)"
                                    @contextmenu="emitActivityEvent(truck)"
                                    @pinTruck="emitPinTruck(truck)"
                                    @detailTruckHooked="emitDetailTruckHooked"
                                    @manager="emitManagerEvent(truck)"
                                    @analyze="emitActivityEvent(truck)"
                                    @plan="emitPlanEvent(truck)"
                                    :id="'pinned-' + truck.id"
                                >
                                </truck-list-item>
                            </b-collapse>
                        </div>
                    </template>

                    <template v-slot="{ item }">
                        <truck-list-item
                            class="truck-list-item"
                            :truck="item"
                            :driver="localDrivers[item.lastpoint.driver]"
                            :key="item.plate"
                            :collapsed="mainCollapsed"
                            @click="emitFocusEvent(item)"
                            @contextmenu="emitActivityEvent(item)"
                            @pinTruck="emitPinTruck(item)"
                            @detailTruckHooked="emitDetailTruckHooked"
                            @manager="emitManagerEvent(item)"
                            @analyze="emitActivityEvent(item)"
                            @plan="emitPlanEvent(item)"
                        />
                    </template>
                </recycle-scroller>
            </template>

            <p class="p-3 text-center" v-if="countEntries(localTrucks, 'visible', true) == 0">
                {{ __('no_vehicles_founded') }}
            </p>
        </template>
    </side-panel>
</template>

<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

import SidePanel from '@/components/panels/SidePanel.vue'

//MAIN PANEL
import InputSearch from './MainPanel/InputSearch.vue'
import TruckListItem from './MainPanel/TruckListItem.vue'
import SortTrucks from './MainPanel/SortTrucks.vue'
import FilterTrucks from './MainPanel/FilterTrucks.vue'
import TableViewTruckList from './MainPanel/TableViewTruckList.vue'
import { RecycleScroller } from 'vue-virtual-scroller'

import { mapState, mapGetters, mapMutations } from 'vuex'

import { buildVehicleAlerts } from '@/functions/checkVehicleAlerts.js'

let defaultFilters = {
    // Vehicle
    tags: [],
    engine_rpm: 0,
    ignition: 0,
    moving: 0,
    // Alerts
    low_fuel: false,
    low_battery: false,
    tacho_overspeed: false,
    no_power_supply: false,
    no_gps: false,
    no_points: false,
    tacho_clock_error: false,
    // Driver
    activity: 4,
    no_card: false,
    codriving: false,
    residual_commitment_type: 'daily',
    residual_commitment: null
}

export default {
    name: 'MainPanel',
    props: {
        trucks: {
            type: Array,
            required: false,
            default: () => []
        },
        drivers: {
            type: [Object, Array],
            required: false,
            default: () => {}
        },
        companies: {
            type: [Object, Array],
            required: false,
            default: () => {}
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        last_update: {
            type: String,
            required: false
        },
        direction: {
            type: String,
            required: false,
            default: 'vertical'
        }
    },
    components: {
        SidePanel,
        InputSearch,
        TruckListItem,
        SortTrucks,
        FilterTrucks,
        RecycleScroller,
        TableViewTruckList
    },
    data() {
        return {
            q: null,
            q_fields: [
                'plate',
                'details.custom_id',
                'lastpoint.last_driver.name',
                'lastpoint.last_driver.surname',
                'details.brand',
                'details.model'
            ],

            pinEvent: false,
            pinCollapse: false,
            sort: {
                alphabetic: {
                    active: false,
                    order: 'asc',
                    fields: ['details.custom_id', 'plate'],
                    label: this.__('sort_for_alphanumeric')
                },
                cronological: {
                    active: false,
                    order: 'asc',
                    fields: ['lastpoint.date'],
                    label: this.__('sort_for_lastpoint')
                },
                company: {
                    active: false,
                    order: 'asc',
                    fields: ['details.company'],
                    label: this.__('sort_for_company')
                },
                activity: {
                    active: false,
                    order: 'asc',
                    fields: ['activity_type'],
                    label: this.__('sort_for_activity')
                }
            },
            sortAction: [],
            filterCompanies: {},

            filterSearchToggle: false,
            nearVehicles: {
                searchLocation: null,
                searchResults: [],
                searchedVehicles: [],
                areaSearch: null,
                areaUnit: 'km',
                active: false
            },
            activeFilters: {},

            localTrucks: [],
            localDrivers: {},
            localCompanies: {},

            ignore_scroll: false,

            placeholderItemHeight: {
                current: 125,
                last: 125,
                interval: null,
                default: 125
            }
        }
    },
    methods: {
        resetSearch() {
            this.q = null
        },
        scrollTopVehicles() {
            this.$refs['dynamic-truck-scroller'].scrollToItem(0)
        },
        loadData() {
            // Load trucks from prop (if exists) or from store
            // (sharedLinksViews inherit trucks from prop, geoFleetView inherit trucks from store)
            if (this.trucks && this.trucks.length > 0) {
                this.localTrucks = this.trucks
            } else {
                this.localTrucks = this.storeTrucks
            }

            // Load companies and drivers from prop (if exists) or from store
            if (this.drivers && Object.keys(this.drivers).length > 0) {
                this.localDrivers = this.drivers
            } else {
                this.localDrivers = this.storeDrivers
            }

            if (this.companies && Object.keys(this.companies).length > 0) {
                this.localCompanies = this.companies
            } else {
                this.localCompanies = this.storeCompanies
            }
        },
        truckFocus(truck) {
            if (!this.ignore_scroll)
                this.$refs['dynamic-truck-scroller'].scrollToPosition(
                    truck.index * this.placeholderItemHeight.current
                )

            this.ignore_scroll = false
        },
        emitPinTruck(truck) {
            this.ignore_scroll = true

            truck.pinned = !truck.pinned

            if (!this.pinEvent) {
                this.pinEvent = true

                debounce(() => {
                    this.pinEvent = false

                    this.$store.dispatch('saveConfig', {
                        pinned_trucks: this.computedPinnedTrucks,
                        vueInstance: this
                    })
                }, 5000)()
            }
        },
        emitDetailTruckHooked(plate) {
            let truck = this.trucks.find((t) => t.plate === plate)
            this.emitFocusEvent(truck)
        },
        emitFocusEvent(truck, optional_actions) {
            this.ignore_scroll = true

            // Per la versione mobile il focus viene gestito solo sullo store perchè non vi è il componente padre su cui emettere l'evento
            // Inoltre, se siamo in mobile, viene aperta la pagina di dettaglio del veicolo
            if ((this.$mq == 'xs' || this.$mq == 'sm') && this.direction != 'horizontal') {
                this.$store.commit('setFocusTruck', { truck: truck, on_focus: true })

                this.$router.push({ name: 'geofleet.vehicle_details', params: { id: truck.plate } })
            }

            this.$emit('focus', truck, optional_actions)
        },
        emitActivityEvent(truck) {
            this.ignore_scroll = true

            this.$emit('activity', truck)
        },
        emitPlanEvent(truck) {
            this.ignore_scroll = true

            this.$emit('plan', truck)
        },
        emitManagerEvent(truck) {
            this.ignore_scroll = true

            this.$emit('manager', truck)
        },
        checkActiveCompany(companyId) {
            if (Object.keys(this.localCompanies).length > 0 && this.filterCompanies[companyId]) {
                return this.filterCompanies[companyId].active === true
            }
        },
        checkActiveTrucksTags(truckTags, searchedTags) {
            truckTags = truckTags || []

            if (searchedTags.length > 0) {
                let tagsArray = truckTags.map((tag) => tag.id)
                searchedTags = searchedTags.map((tag) => tag.id)

                // check if all tags exist in searchedTags
                var exist = searchedTags.every((tag) => tagsArray.includes(tag))

                return exist
            }
        },
        toggleSorter(target) {
            if (this.sort[target].active === true) {
                // if not already exist, add to sortAction
                let exist = this.sortAction.findIndex((obj) => obj.key === this.sort[target].fields)

                if (exist === -1) {
                    this.sortAction.push({
                        key: this.sort[target].fields,
                        order: this.sort[target].order
                    })
                } else {
                    this.sortAction.splice(exist, 1, {
                        key: this.sort[target].fields,
                        order: this.sort[target].order
                    })
                }
            } else {
                // if not already exist, remove from sortAction
                let exist = this.sortAction.findIndex((obj) => obj.key === this.sort[target].fields)

                if (exist !== -1) {
                    this.sortAction.splice(exist, 1)
                }
            }
        },
        sortByKey(criteria) {
            return (a, b) => {
                let returnValue = 0
                criteria.find((c) => (returnValue = this.compareByKey(c, a, b)))
                return returnValue
            }
        },
        compareByKey({ key, order = 'asc' }, a, b) {
            var varA = null
            var varB = null

            varA = this.resolvePath(key[0], a) || this.resolvePath(key[1], a)
            varB = this.resolvePath(key[0], b) || this.resolvePath(key[1], b)

            let comparison = 0

            if (varA > varB) {
                comparison = 1
            } else if (varA < varB) {
                comparison = -1
            }

            if (order === 'desc') {
                comparison *= -1
            }

            return comparison
        },
        resetFilerFields() {
            this.filterSearchToggle = false

            // Reset filters
            this.activeFilters = cloneDeep(defaultFilters)

            // Reset near vehicles
            this.nearVehicles.active = false
            this.nearVehicles.areaSearch = null
            this.nearVehicles.vehiclesList = []
            this.nearVehicles.searchLocation = null
            this.nearVehicles.searchResults = []

            // Reset search
            this.q = null

            this.setTruckFilteredOut([])

            // Tolgo dalla pausa l'auto fit bounds della mappa principale
            this.$store.commit('setMainMapStopAutoFitBounds', false)

            return true
        },
        triggerFilter(filter) {
            this.activeFilters[filter] = !this.activeFilters[filter]

            this.filterTrucks(false)
        },
        async filterTrucks(closeModal) {
            this.filterSearchToggle = true

            let truck_filtered_out = []

            let q_reg = this.q ? new RegExp(this.q.trim(), 'i') : null

            let scopedSearch = null

            if (this.nearVehicles.searchLocation && this.nearVehicles.areaSearch)
                scopedSearch = await this.searchNearbyVehicles()
            else this.nearVehicles.active = false

            for (var truck of this.storeTrucks) {
                // Vehicle alerts building for filtering
                let alerts = buildVehicleAlerts(truck)

                truck.alerts = alerts

                // Query String
                if (q_reg) {
                    if (!truck.q_string) {
                        truck.q_string = ''

                        for (var f of this.q_fields) {
                            var query = this.resolvePath(f, truck)

                            if (query) {
                                truck.q_string += String(query)
                            }
                        }
                    }

                    if (!q_reg.test(truck.q_string)) {
                        truck_filtered_out.push(truck.id)
                        continue
                    }
                }

                // Tags
                if (
                    this.activeFilters.tags.length > 0 &&
                    !this.checkActiveTrucksTags(truck.tags, this.activeFilters.tags)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Engine RPM ON
                let filter_rpm = parseInt(this.activeFilters.engine_rpm || 0)

                if (
                    filter_rpm == 1 &&
                    (truck.lastpoint.engine_rpm == null || truck.lastpoint.engine_rpm <= 0)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Engine RPM OFF
                if (filter_rpm == 2 && truck.lastpoint.engine_rpm > 0) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Ignition ON
                if (
                    this.activeFilters.ignition == 1 &&
                    (truck.lastpoint.ignition == null || truck.lastpoint.ignition < 1)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Ignition OFF
                if (
                    this.activeFilters.ignition == 2 &&
                    (truck.lastpoint.ignition == null || truck.lastpoint.ignition >= 1)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Low fuel
                if (
                    this.activeFilters.low_fuel === true &&
                    (truck.lastpoint.fuel_level == null ||
                        (truck.lastpoint.fuel_level !== null && truck.lastpoint.fuel_level > 20))
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Low battery
                if (
                    this.activeFilters.low_battery === true &&
                    truck.lastpoint.battery_level !== null &&
                    truck.lastpoint.battery_level > 20
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Tacho overspeed
                if (
                    this.activeFilters.tacho_overspeed === true &&
                    (truck.alerts.tacho_overspeed == null || truck.alerts.tacho_overspeed == false)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // No power supply
                if (
                    this.activeFilters.no_power_supply === true &&
                    (truck.alerts.no_power_supply == null || truck.alerts.no_power_supply == false)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // No GPS
                if (
                    this.activeFilters.no_gps === true &&
                    (truck.alerts.no_gps == null || truck.alerts.no_gps == false)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // No points
                if (
                    this.activeFilters.no_points === true &&
                    (truck.alerts.no_points == null || truck.alerts.no_points == false)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Tacho clock error
                if (
                    this.activeFilters.tacho_clock_error === true &&
                    (truck.alerts.tacho_clock_error == null ||
                        truck.alerts.tacho_clock_error == false)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Any problem (with low_battery or low_fuel)
                if (
                    this.activeFilters.any_problem === true &&
                    Object.values(truck.alerts).length === 0
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                let speed = parseInt(truck.lastpoint.tacho_speed || truck.lastpoint.speed)

                // Moving vehicles
                if (this.activeFilters.moving == 1 && !isNaN(speed) && speed <= 0) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Stopped vehicles
                if (this.activeFilters.moving == 2 && !isNaN(speed) && speed > 0) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                if (
                    this.activeFilters.activity >= 0 &&
                    this.activeFilters.activity <= 3 &&
                    this.activeFilters.activity !== '' &&
                    truck.lastpoint.activity != this.activeFilters.activity
                ) {
                    console.log(
                        'activity by number',
                        this.activeFilters.activity,
                        truck.lastpoint.activity
                    )
                    truck_filtered_out.push(truck.id)
                    continue
                }

                if (
                    this.activeFilters.activity === '' &&
                    truck.lastpoint.activity !== null &&
                    truck.lastpoint.activity !== undefined
                ) {
                    console.log(
                        'activity by null',
                        this.activeFilters.activity,
                        truck.lastpoint.activity
                    )
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Driving with no card
                if (
                    this.activeFilters.no_card === true &&
                    (truck.type == 3 ||
                        truck.lastpoint.driver == null ||
                        truck.lastpoint.driver.id != 1 ||
                        (truck.lastpoint.driver.id == 1 && speed == 0))
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Codriving
                if (
                    this.activeFilters.codriving === true &&
                    (truck.lastpoint.codriver == null || truck.lastpoint.codriver.id <= 1)
                ) {
                    truck_filtered_out.push(truck.id)
                    continue
                }

                // Residual commitment
                if (this.activeFilters.residual_commitment > 0) {
                    let totalDrivingTime = 0

                    totalDrivingTime +=
                        this.driversData[truck.lastpoint?.driver?.id]?.lefts?.today || 0

                    totalDrivingTime +=
                        this.driversData[truck.lastpoint?.codriver?.id]?.lefts?.today || 0

                    if (totalDrivingTime < this.activeFilters.residual_commitment * 60 * 60) {
                        truck_filtered_out.push(truck.id)
                        continue
                    }
                }

                if (scopedSearch && scopedSearch.indexOf(truck.id) == -1) {
                    truck_filtered_out.push(truck.id)
                    continue
                }
            }

            if (closeModal) this.$bvModal.hide('modal-search-trucks')

            this.setTruckFilteredOut(truck_filtered_out)

            // this.$emit('searching', { resetFocus: false })
        },
        async searchNearbyVehicles() {
            this.nearVehicles.active = true

            let area = this.nearVehicles.areaSearch
            let lat = this.nearVehicles.searchLocation.referenceCoordinate.y
            let lng = this.nearVehicles.searchLocation.referenceCoordinate.x
            let results = []

            let url =
                this.$env.GOLIA_API +
                '/trucks?include[]=lastpoint&filter[VISIBILE][eq]=1&area[lat]=' +
                lat +
                '&area[lng]=' +
                lng +
                '&area[r]=' +
                area

            results = await this.$fetch(url)
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    return res.data.map((truck) => truck.id)
                })

            if (results.length > 0) {
                this.nearVehicles.searchedVehicles = results
                return results
            }
        },
        searchTrucks: debounce((newV, vm) => {
            vm.q = newV

            vm.filterTrucks(false)
        }, 500),
        calculateItemsHeight() {
            // Riferimento all'elemento padre
            const parentElement = document.querySelector('.vue-recycle-scroller__item-wrapper')

            if (!parentElement) return 0

            // Riferimento a tutti gli elementi con classe "vue-recycle-scroller__item-view" all'interno del padre
            const elements = parentElement.getElementsByClassName('vue-recycle-scroller__item-view')

            let maxHeight = 0

            // Iterazione su tutti gli elementi per trovare l'altezza massima
            for (let i = 0; i < elements.length; i++) {
                const elementHeight = elements[i].offsetHeight

                if (elementHeight > maxHeight) {
                    maxHeight = elementHeight
                }
            }

            return maxHeight
        },
        setMainPanelHeights(with_interval) {
            if (with_interval) {
                this.placeholderItemHeight.interval = setInterval(() => {
                    if (this.calculateItemsHeight() > 0) {
                        // Se dovessero in futuro ripresentarsi problemi di ricalcolo, allora si può pensare di togliere il clearInterval
                        if (this.placeholderItemHeight.current > this.placeholderItemHeight.default)
                            clearInterval(this.placeholderItemHeight.interval)

                        if (!this.mainCollapsed) {
                            this.placeholderItemHeight.current = this.calculateItemsHeight()
                            this.placeholderItemHeight.last = this.placeholderItemHeight.current
                        }
                    }
                }, 1000)
            } else {
                let calculatedHeight = this.calculateItemsHeight()

                if (calculatedHeight > 0 && !this.mainCollapsed) {
                    this.placeholderItemHeight.current = calculatedHeight
                    this.placeholderItemHeight.last = this.placeholderItemHeight.current
                }
            }
        },
        ...mapMutations({
            setTruckFilteredOut: 'setTruckFilteredOut',
            toggleCollapsePanel: 'toggleCollapsePanel'
        })
    },
    watch: {
        localCompanies: {
            handler: function (val) {
                if (Object.keys(val).length > 0) {
                    this.filterCompanies = Object.assign({}, val)

                    for (const i in this.filterCompanies) {
                        this.filterCompanies[i] = { name: this.filterCompanies[i], active: true }
                    }
                }
            }
        },
        filterCompanies: {
            handler: function () {
                // migrate this to a method
                let truck_filtered_out = []

                if (Object.keys(this.localCompanies).length > 0) {
                    for (var truck of this.storeTrucks) {
                        if (
                            (truck.details.secondary_company !== null &&
                                !this.checkActiveCompany(truck.details.secondary_company)) ||
                            (truck.details.secondary_company === null &&
                                !this.checkActiveCompany(truck.details.company))
                        ) {
                            truck_filtered_out.push(truck.id)
                        } else if (truck_filtered_out.indexOf(truck.id) > -1) {
                            truck_filtered_out.splice(truck_filtered_out.indexOf(truck.id), 1)
                        }
                    }

                    this.setTruckFilteredOut(truck_filtered_out)
                }
            },
            deep: true
        },
        trucks: {
            handler: function (val) {
                this.localTrucks = val
                // let marino = 0, auto = 0, maco = 0;

                // this.localTrucks.map(truck => {
                //     if(truck.details.secondary_company == 2212 || (truck.details.secondary_company === null && truck.details.company == 2212)) {
                //         marino++;
                //         console.log(truck.plate + '| details.secondary_company', truck.details.secondary_company, 'details.company', truck.details.company + ' -> Marino')

                //         return;
                //     }
                //     if(truck.details.secondary_company == 2345 || (truck.details.secondary_company === null && truck.details.company == 2345)) {
                //         auto++;
                //         console.log(truck.plate + '| details.secondary_company', truck.details.secondary_company, 'details.company', truck.details.company + ' -> Auto')
                //         return;
                //     }
                //     if(truck.details.secondary_company == 2346 || (truck.details.secondary_company === null && truck.details.company == 2346)) {
                //         maco++;
                //         console.log(truck.plate + '| details.secondary_company', truck.details.secondary_company, 'details.company', truck.details.company + ' -> Maco')
                //         return;
                //     }
                // })

                // console.log('Totals - ' + 'Marino: ' + marino + ' | Auto: ' + auto + ' | Maco: ' + maco)
            },
            deep: true
        },
        drivers: {
            handler: function (val) {
                this.localDrivers = val
            },
            deep: true
        },
        companies: {
            handler: function (val) {
                this.localCompanies = val
            },
            deep: true
        },
        storeTrucks: {
            handler: function (val) {
                this.localTrucks = val
            },
            deep: true
        },
        last_update: {
            handler: function () {
                if (this.sortAction.length > 0) {
                    // SORTER
                    this.localTrucks.sort(this.sortByKey(this.sortAction))
                }

                if (
                    this.activeSearches > 0 &&
                    this.filterSearchToggle &&
                    !this.nearVehicles.active
                ) {
                    // Metto in pausa l'auto fit bounds della mappa principale
                    this.$store.commit('setMainMapStopAutoFitBounds', true)

                    this.filterTrucks(false)
                }
            },
            immediate: true
        },
        q(newV) {
            this.searchTrucks(newV, this)
        },
        sortAction: {
            handler: function () {
                if (this.sortAction.length > 0) {
                    // SORTER
                    this.localTrucks.sort(this.sortByKey(this.sortAction))
                } else {
                    this.localTrucks.sort(this.sortByKey([{ key: ['id'] }]))
                }
            },
            deep: true,
            immediate: true
        },
        activeFilters: {
            handler: function () {
                if (this.activeFilters.length > 0) {
                    this.activeFilters.map((filter) => {
                        this.localTrucks.map((truck) => {
                            if (this.resolvePath(filter.key, truck) == filter.value) {
                                truck.visible = true
                            }
                        })
                    })
                }
            },
            deep: true
        }
    },
    computed: {
        visibleTrucks() {
            return this.localTrucks.filter((truck) => truck.visible).length
        },
        listActiveTrucks() {
            return this.localTrucks.filter(
                (truck) => this.truck_filtered_out.indexOf(truck.id) == -1 && !truck.pinned
            )
        },
        companyCounts() {
            const counts = {}

            for (const companyId in this.localCompanies) {
                counts[companyId] = 0
            }

            this.storeTrucks.forEach((truck) => {
                const companyId = truck.details.secondary_company || truck.details.company

                if (companyId in counts) {
                    counts[companyId]++
                }
            })

            return counts
        },
        activeSearches() {
            let counter = 0

            this.activeFilters?.tags?.length > 0 ? counter++ : null
            this.activeFilters.activity != 4 ? counter++ : null
            this.activeFilters.engine_rpm > 0 ? counter++ : null
            this.activeFilters.ignition > 0 ? counter++ : null
            this.activeFilters.low_fuel === true ? counter++ : null
            this.activeFilters.moving > 0 ? counter++ : null
            this.activeFilters.no_card === true ? counter++ : null
            this.activeFilters.codriving === true ? counter++ : null
            this.activeFilters.residual_commitment > 0 ? counter++ : null

            if (this.nearVehicles.active) {
                counter++
            }

            return counter
        },
        sortedFields() {
            var fields = 0

            for (var key in this.sort) {
                if (this.sort[key] > 0) {
                    fields++
                }
            }

            return fields
        },
        computedPinnedTrucks: {
            get() {
                return this.localTrucks.filter((truck) => truck.pinned)
            },
            set(storePinnedTrucks) {
                this.localTrucks.map((truck) => {
                    truck.pinned = storePinnedTrucks.find(
                        (storePinnedTruck) => storePinnedTruck.id == truck.id
                    )
                        ? true
                        : false
                })
            }
        },
        vehicleListModeView() {
            return this.config.vehicleListModeView ? this.config.vehicleListModeView : 'list'
        },
        ...mapState({
            config: (state) => state.geoFleet.config,
            storeTrucks: (state) => state.geoFleet.trucks,
            storeDrivers: (state) => state.geoFleet.drivers,
            storeCompanies: (state) => state.geoFleet.companies,
            sharedLinks: (state) => state.sharedLinks.links,
            driversData: (state) => state.geoFleet.driversData,
            truck_filtered_out: (state) => state.geoFleet.truckFilteredOut
        }),
        ...mapGetters({
            driversDataArray: 'getArrayOfDriversData',
            mainCollapsed: 'mainCollapsed',
            getTrucksWithAlerts: 'getTrucksWithAlerts'
        })
    },
    created() {
        this.activeFilters = cloneDeep(defaultFilters)

        this.loadData()

        // Load pinned trucks from store
        if (this.config && this.config.pinned_trucks && this.config.pinned_trucks.length > 0) {
            this.computedPinnedTrucks = this.config.pinned_trucks
        }

        if (this.direction == 'horizontal') this.placeholderItemHeight.current = 75
    },
    mounted() {
        if (this.direction != 'horizontal') {
            // Keep running this.calculateItemsHeight() every 1s and stop it if current > default (so, if the height is calculated at least once)
            if (this.config?.vehicleListModeView != 'table') this.setMainPanelHeights(true)
        }

        this.$watch(
            () => this.mainCollapsed,
            (val) => {
                if (val) {
                    this.placeholderItemHeight.current = 75
                } else {
                    this.placeholderItemHeight.current = this.placeholderItemHeight.last
                }
            }
        )

        this.$watch(
            () => this.config?.vehicleListModeView,
            (val) => {
                this.$store.commit('setTimedLoading', 500)

                if (this.config?.vehicleListModeView != 'table') this.setMainPanelHeights(true)
            }
        )

        this.$watch(
            () => this.config?.vehicleIdentification,
            () => {
                setTimeout(() => {
                    this.setMainPanelHeights()
                }, 500)
            }
        )
    }
}
</script>

<style lang="scss">
.btn-scroll-vehicles {
    position: absolute;
    bottom: 0px;
    right: calc(100% + 15px);
    background: #fff;
    opacity: 0;
    height: 0px;
    transition: all 0.3s ease-in-out;
    min-width: 200px;

    &.animated {
        height: auto;
        opacity: 1;
        transition: all 0.5s;
    }
}
.btn-close-scroll-vehicles {
    margin: 0 auto 15px;
    transform: translateX(15px);
    display: block;

    i {
        font-size: 12px;
    }
}
#modal-search-trucks {
    .form-control,
    .form-select {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .btn {
        border-radius: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
</style>

<style scoped lang="scss">
@import 'MainPanel/styles/MainPanel.scss';
</style>
